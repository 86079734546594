.game-board {
    background-color: silver;
    width: 100vw;
    height: 80vh;
    margin: auto;
    display: grid;
    grid-template-rows : 50% 50%;
    grid-template-columns: 50% 50%;
}

.instructions {
    font-size: clamp(2rem, 10vh, 8rem);
    font-weight:bolder;
    background-color: darkgrey;
    margin: auto;
    width: 100vw;
}

@media (orientation: landscape) {
    .game-board {
        background-color: silver;
        width: 80vw;
        height: 80vh;
        margin: auto;
    }    

    .instructions {
        font-size: clamp(2rem, 8vw, 8rem);
        font-weight:bolder;
        width: 80vw;
    }    
}