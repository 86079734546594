.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 10;
    background: rgba(0, 0, 0, 0.9);
  }
  
  .modalContainer {
    display: flex;
    justify-content: center;
  }

  .modal {
    position: fixed;
    top: 10vh;
    z-index: 100;
    overflow: hidden;
    font-size: clamp(0.75rem, 5.5vw, 2.75rem);
    width: clamp(200px, 80vw, 1000px);
  }

  .description {
    text-align: justify;
    margin: 0px;
  }

  .levelContainer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .radioItem {
    display: block;
    flex-grow: 1;
    padding: 1em;
  }

  .radioItem input {
    margin: 5px;
    vertical-align: middle;
  }


  @media screen and (max-width: 700px ){

    .levelContainer {
      display: grid;
      justify-content: center;
      grid-auto-rows: max-content;
    }

    .radioItem {
      display: block;
      padding: 0.5em;
      padding-bottom: 0px;

    }    
  }

  @media (orientation: landscape) {
    .modal {
      top: 5vh;
      font-size: clamp(0.75rem, 5.5vh, 2.5rem);
    }
  }